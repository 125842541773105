<template>
  <v-container fluid>
    <v-form ref="form_notes" lazy-validation>
      <v-row
        dense
        align="center"
      >
        <v-col cols="12" sm="3">
          <span><b>No Kargo: {{$route.query.title}}</b></span>
        </v-col>
        <v-col cols="12" sm="4">
          <v-skeleton-loader type="heading" v-if="isLoadingLatestStatus"></v-skeleton-loader>
          <span v-else><b>Status: {{latestStatus}} </b></span>
        </v-col>
        <v-col
          v-if="userAccess.canCreate"
        >
          <v-btn
            color="primary"
            outlined
            @click="showDialogUploadDocument"
            class="float-right"
          >
            {{$_strings.dokumenPod.UPLOAD_DOCUMENT}}
          </v-btn>
        </v-col>
      </v-row>
      <br>
      <v-data-table
        :loading="isLoading"
        :headers="displayedHeader"
        :items="items"
        item-key="id"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      >
        <template v-slot:[`item.documentType`]=items>
          <span>{{ formatTypeDokumenPOD(items.item.documentType) }}</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="edit(false, item)"
              >
                <v-icon>mdi-file-find</v-icon>
              </v-btn>
            </template>
            <span>Lihat Detail</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="edit(true, item)"
                :disabled="
                  latestStatus === 'Dokumen Dikirim Ke Shipper'
                    || latestStatus === 'Belum Kirim Dokumen'
                ">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.isRevise`]={item}>
          <span>{{item.isRevise ? 'Ya' : 'Tidak'}}</span>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.dokumenPod.PAGE_NAME}}
            <span v-if="items.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>
      </v-data-table>
      <v-row class="mt-5">
        <v-col cols="auto" sm="2">
          <v-btn
            elevation="2"
            class="float-left"
            @click="$router.go(-1)"
          >
            <b>{{$_strings.dokumenPod.buttonKembali}}</b>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="10">
          <v-row class="flex justify-end">
            <v-col cols="auto">
              <v-btn
                elevation="2"
                class="float-right"
                color="primary"
                @click="sendDocumentToShipper"
                :disabled="
                  !!hasRevision
                    || !latestStatus
                    || latestStatus === 'Dokumen Terkirim'
                    || latestStatus === 'Dokumen Dikirim Ke Shipper'
                    || latestStatus === 'Belum Kirim Dokumen'
                "
              >
                <b>{{$_strings.dokumenPod.buttonSendDokumen}}</b>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                elevation="2"
                color="primary"
                class="float-right mr-2"
                @click="showDialogConfirmation('inCompleteDocument')"
                :disabled="!!hasRevision
                  || !latestStatus
                  || latestStatus === 'Dokumen Tidak Lengkap'
                  || latestStatus === 'Dokumen Dikirim Ke Shipper'
                  || latestStatus === 'Belum Kirim Dokumen'
                "
              >
                <b>{{$_strings.dokumenPod.buttonDokumenTidakLengkap}}</b>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                elevation="2"
                class="mr-2 float-right"
                color="primary"
                @click="showDialogConfirmation('completeDocument')"
                :disabled="
                  !!hasRevision
                    || !latestStatus
                    || items.length === 0
                    || latestStatus === 'Dokumen Dikirim Ke Shipper'
                    || latestStatus === 'Dokumen Lengkap'
                    || latestStatus === 'Belum Kirim Dokumen'
                "
              >
                <b>{{$_strings.dokumenPod.buttonDokumen}}</b>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <DialogUploadDocument
      ref="dialogUploadDocument"
      :itemsLocation="itemsLocation"
      :itemsShipment="itemsShipment"
      @fetchList="fetchDokumenPod"
    />
    <Edit
      :setDialogEdit="setDialog"
      :dialogEdit="dialogEdit"
      :setEditDokumen="temporaryEdit"
      :setShipment="temporaryShipment"
      :setLocation="temporaryLocation"
      :shipmentDocumentPOD="shipmentDocumentPOD"
      @fetchDokumenPod="fetchDokumenPod"
      ref="dialogEditDocument"
    />
    <DialogConfirmation
      ref="dialogConfirmation"
      @completeDocument="completeDocument"
      @inCompleteDocument="inCompleteDocument"
    />
  </v-container>
</template>

<script>
import DialogUploadDocument from './Dialog/UploadDocument.vue';
import DialogConfirmation from './Dialog/Confirmation.vue';
import { typeDokumenPOD } from '../../helper/commonHelpers';
import Edit from './Dialog/Edit.vue';

export default {
  name: 'dokumenPod-list',
  components: {
    DialogConfirmation,
    DialogUploadDocument,
    Edit,
  },
  data() {
    return {
      isLoading: false,
      isLoadingLatestStatus: false,
      dialogEdit: false,
      temporaryEdit: {},
      temporaryShipment: [],
      temporaryLocation: [],
      cargoId: 0,
      items: [],
      itemsShipment: [],
      itemsLocation: [],
      headers: [
        {
          text: this.$_strings.dokumenPod.headerTipeDokumen,
          value: 'documentType',
        },
        {
          text: this.$_strings.dokumenPod.headerNamaDokumen,
          value: 'name',
        },
        {
          text: this.$_strings.dokumenPod.headerAksi,
          value: 'action',
          sortable: false,
        },
        {
          text: this.$_strings.dokumenPod.headerRevise,
          value: 'isRevise',
          sortable: false,
        },
      ],
      pagination: {
        itemPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      totalItems: 0,
      ListPage: 1,
      ListSize: 10,
      itemsTable: [],
      shipmentDocumentPOD: [],
      latestStatus: null,
      isDisabledButtonSendDocument: true,
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
    hasRevision() {
      const docRevise = this.items.filter((doc) => doc.isRevise === true);
      return docRevise.length;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchDokumenPod();
      },
      deep: true,
    },
  },
  methods: {
    showDialogConfirmation(action) {
      let msg = '';
      if (action === 'completeDocument') msg = 'Apakah Anda Yakin Dokumen Sudah Lengkap ?';
      if (action === 'inCompleteDocument') msg = 'Apakah Anda Yakin Dokumen Tidak Lengkap ?';
      this.$refs.dialogConfirmation.title = `<div class="font-weight-bold"><p class="text-center mb-0">${msg}</p></div>`;
      this.$refs.dialogConfirmation.action = action;
      this.$refs.dialogConfirmation.dialog = true;
    },
    handleNotes(event, item) {
      if (!event) {
        const index = this.items.indexOf(item);
        this.items[index].notes = '';
      }
    },
    showDialogUploadDocument() {
      this.$refs.dialogUploadDocument.dialog = true;
      this.$refs.dialogUploadDocument.isEdit = false;
    },
    async completeDocument() {
      try {
        this.$root.$loading.show();
        const { cargoId } = this.$route.params;
        const status = 'DOKUMEN_LENGKAP';
        await this.$_services.dokumenPod.updateStatusDocumentPod(cargoId, status, this.items);
        this.isDisabledButtonSendDocument = false;
        this.$dialog.notify.success('Berhasil');
        this.fetchLatestStatus();
      } finally {
        this.$root.$loading.hide();
      }
    },
    async inCompleteDocument() {
      const isValid = this.$refs.form_notes.validate();
      if (!isValid) return this.$dialog.notify.error('Mohon input catatan');
      try {
        this.$root.$loading.show();
        const { cargoId } = this.$route.params;
        const status = 'DOKUMEN_TIDAK_LENGKAP';
        await this.$_services.dokumenPod.updateStatusDocumentPod(cargoId, status, this.items);
        this.isDisabledButtonSendDocument = true;
        this.$dialog.notify.success('Berhasil');
        this.fetchLatestStatus();
      } finally {
        this.$root.$loading.hide();
      }
    },
    async sendDocumentToShipper() {
      try {
        this.$root.$loading.show();
        const { cargoId } = this.$route.params;
        const status = 'DOKUMEN_DIKIRIM_KE_SHIPPER';
        await this.$_services.dokumenPod.updateStatusDocumentPod(cargoId, status, this.items);
        this.isDisabledButtonSendDocument = false;
        this.$dialog.notify.success('Berhasil');
        this.fetchLatestStatus();
      } finally {
        this.$root.$loading.hide();
      }
    },
    edit(isEdit = false, item) {
      let shipmentDocumentPOD = [];
      if (!item.isRevise) {
        shipmentDocumentPOD = this.shipmentDocumentPOD.filter((doc) => doc.name === item.name && !doc.isRevise);
      } else {
        shipmentDocumentPOD.push(item);
      }
      this.$refs.dialogEditDocument.itemsShipment = this.itemsShipment;
      this.$refs.dialogEditDocument.itemsLocation = this.itemsLocation;
      this.$refs.dialogEditDocument.documentListItems = JSON.parse(JSON.stringify(shipmentDocumentPOD));
      this.$refs.dialogEditDocument.isEdit = isEdit;
      this.$refs.dialogEditDocument.dialog = true;
    },
    setDialog(dialogBy) {
      this[dialogBy] = !this[dialogBy];
    },
    async fetchLatestStatus() {
      const { dateFrom, dateTo, title } = this.$route.query;
      const filters = {
        page: 0,
        size: 1,
        sort: '',
        dateFrom,
        dateTo,
        title,
      };
      try {
        this.isLoadingLatestStatus = true;
        const result = await this.$_services.dokumenPod.dokumenPodList(filters);
        if (result.data.contents.length) this.latestStatus = result.data.contents[0].latestStatus;
      } finally {
        this.isLoadingLatestStatus = false;
      }
    },
    fetchDokumenPod() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: this.searchText,
        sort: '',
        cargoId: this.cargoId,
      };
      if (filters.size < 0) filters.size = this.totalItems;
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      this.isLoading = true;
      this.$_services.dokumenPod.dokumenPodDetail(filters)
        .then((result) => {
          this.shipmentDocumentPOD = result.data.shipmentDocumentPOD.map((data) => {
            if (data.isRevise === null) {
              return {
                ...data,
                isRevise: false,
              };
            }
            return data;
          });
          this.items = [];
          const itemsRevise = [];
          this.shipmentDocumentPOD.forEach((doc) => {
            if (doc.isRevise) itemsRevise.push(doc);
            if (!(this.items.find((item) => item.name === doc.name)) && !doc.isRevise) this.items.push(doc);
          });
          this.items = [...this.items, ...itemsRevise];
          this.itemsShipment = result.data.shipments;
          this.itemsLocation = result.data.locations;
          this.totalItems = result.data.totalData;
          this.ListPage = result.data.page;
        })
        .finally((e) => {
          this.isLoading = false;
        });
    },
    formatTypeDokumenPOD(documentType) { return typeDokumenPOD(documentType); },
  },
  created() {
    this.cargoId = this.$route.params.cargoId;
    this.documentType = this.items.documentType;
    this.fetchLatestStatus();
  },
};
</script>
