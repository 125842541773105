<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="600"
    >
      <v-card :loading="isLoading">
        <v-card-title class="caption">
          <h2>{{ $_strings.dokumenPod.UPLOAD_DOCUMENT }}</h2>
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                  :disabled="isLoading"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" class="pb-0">
                {{$_strings.dokumenPod.NUMBER_ORDER}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="form.shipmentId"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.dokumenPod.NUMBER_ORDER)]"
                  :items="itemsShipment"
                  item-value="shipmentId"
                  item-text="shipmentName"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                {{$_strings.dokumenPod.DOCUMENT_TYPE}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="form.documentType"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.dokumenPod.DOCUMENT_TYPE)]"
                  :items="listDocumentType"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                {{$_strings.dokumenPod.LOCATION}}
                <span class="error--text d-inline">
                  *
                </span>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="form.locationId"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.dokumenPod.LOCATION)]"
                  :items="itemsLocation"
                  item-value="locationId"
                  item-text="locationName"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <span>
                  {{$_strings.dokumenPod.UPLOAD_DOCUMENT}}
                  <span class="error--text d-inline">
                    *
                  </span>
                </span>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="4" v-for="(subEncodedImages, index) in form.encodedImages" :key="index">
                    <v-card
                      class="mx-auto"
                      outlined
                    >
                      <v-card
                        v-if="!subEncodedImages.encodedImage"
                        height="150"
                        class="d-flex justify-center align-center"
                        flat
                        @click="$refs['file-upload'+index][0].$refs.input.click()"
                      >
                        <v-icon color="grey">
                          mdi-plus
                        </v-icon>
                        <v-file-input
                          class="d-none"
                          :ref="'file-upload'+index"
                          type="file"
                          accept=".jpg,.png,.jpeg,.pdf"
                          @change="(e) => readFile(e, index)"
                          v-model="file"
                        />
                      </v-card>
                      <div
                        v-else
                        @mouseenter="handleHover(index)"
                        @mouseleave="handleHover(index)"
                      >
                        <v-img
                          v-if="subEncodedImages.fileType === 'PDF'"
                          src="@/assets/images/pdf-file.png"
                          aspect-ratio="1"
                          height="150"
                          contain
                          :style="hoverDocument[index].hover ? 'opacity:.5' : ''"
                        />
                        <v-img
                          v-else
                          contain
                          aspect-ratio="1"
                          height="150"
                          :src="subEncodedImages.encodedImage"
                          :style="hoverDocument[index].hover ? 'opacity:.5' : ''"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <div
                          v-if="hoverDocument[index].hover"
                          class="text-center wrapper-trash"
                        >
                          <v-icon
                            color="red"
                            large
                            @click="removeImage(index)"
                          >
                            mdi-trash-can
                          </v-icon>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col
                    v-if="form.encodedImages[form.encodedImages.length - 1].encodedImage"
                    class="d-flex align-center"
                  >
                    <v-icon @click="addDocument" color="blue">
                      mdi-plus-circle
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            @click="dialog = false"
          >
            {{$_strings.common.CANCEL}}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isLoading"
            @click="submit"
          >
            {{ $_strings.common.SAVE}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    itemsLocation: {
      type: Array,
      default: () => [],
    },
    itemsShipment: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      file: null,
      form: {
        documentType: '',
        documentName: '',
        encodedImages: [
          {
            encodedImage: null,
            fileType: '',
          },
        ],
        locationId: '',
        shipmentId: '',
        note: '',
      },
      hoverDocument: [{
        hover: false,
      }],
      listDocumentType: [
        {
          text: 'Foto Muat Barang',
          value: 'FOTO_MUAT_BARANG',
        },
        {
          text: 'Foto Bongkar Barang',
          value: 'FOTO_BONGKAR_BARANG',
        },
        {
          text: 'Foto Surat Jalan',
          value: 'FOTO_SURAT_JALAN',
        },
        {
          text: 'Foto Surat Serah Terima',
          value: 'FOTO_SURAT_SERAH_TERIMA',
        },
        {
          text: 'Foto BASP',
          value: 'FOTO_BASP',
        },
        {
          text: 'Foto Bukti Basp',
          value: 'FOTO_BUKTI_BASP',
        },
        {
          text: 'Foto BAST',
          value: 'FOTO_BAST',
        },
        {
          text: 'Surat Perintah Kiriman',
          value: 'SURAT_PERINTAH_KIRIMAN',
        },
      ],
    };
  },
  methods: {
    resetForm() {
      this.form = {
        documentType: '',
        documentName: '',
        encodedImages: [
          {
            encodedImage: null,
            fileType: '',
          },
        ],
        locationId: '',
        shipmentId: '',
        note: '',
      };
      this.$refs.form.reset();
    },
    handleHover(index) {
      this.hoverDocument[index].hover = !this.hoverDocument[index].hover;
    },
    addDocument() {
      this.form.encodedImages.push({
        encodedImage: null,
        fileType: '',
      });
      this.hoverDocument.push({
        hover: false,
      });
    },
    fileUploadValidation(fileType, size, fileSizeMin = 1024000) {
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    readFile(event, index) {
      if (!event) return;
      const { name, size } = event;
      const fileType = name.split('.').pop().toLowerCase();
      const fileSizeMin = 2048000;
      if (this.fileUploadValidation(fileType, size, fileSizeMin)) {
        const reader = new FileReader();
        reader.readAsDataURL(event);
        reader.onload = (e) => {
          this.form.encodedImages[index].encodedImage = e.target.result;
          this.form.encodedImages[index].fileType = fileType === 'pdf' ? 'PDF' : 'IMAGE';
          this.file = null;
        };
      }
    },
    async removeImage(index) {
      this.form.encodedImages.splice(index, 1);
      this.file = null;
      this.form.encodedImages.splice(index, 1);
      this.hoverDocument.splice(index, 1);
      if (this.form.encodedImages.length === 0) {
        this.form.encodedImages = [
          {
            encodedImage: null,
            fileType: '',
          },
        ];
        this.hoverDocument = [{
          hover: false,
        }];
      }
    },
    validateFileUpload() {
      if (!this.form.encodedImages[0].encodedImage) {
        this.$dialog.notify.error('Wajib Upload dokumen');
        return false;
      }
      return true;
    },
    async uploadPodDocument(index, form) {
      const { shipmentCargoId } = this.$route.params;
      try {
        this.isLoading = true;
        await this.$_services.podDocument.uploadImage(shipmentCargoId, form);
        this.form.encodedImages[index].encodedImage = form.encodedImages[0].encodedImage;
        this.form.encodedImages[index].fileType = form.encodedImages[0].fileType;
        this.isRefetchList = true;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const { cargoId } = this.$route.params;
      const validFileUpload = this.validateFileUpload();
      if (this.$refs.form.validate() && validFileUpload) {
        try {
          this.isLoading = true;
          const newEncodedImages = [];
          this.form.encodedImages.forEach((encoded) => {
            if (encoded.encodedImage) newEncodedImages.push(encoded);
          });
          const form = {
            ...this.form,
            encodedImages: newEncodedImages,
          };
          await this.$_services.dokumenPod.uploadDocument(cargoId, form);
          this.$emit('fetchList');
          this.resetForm();
          this.$dialog.notify.success('Berhasil');
          this.dialog = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .box-upload {
    padding: 20px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
  }
  .box-upload.border-valid {
    border: 1px solid #ddd;
  }
  .box-upload.border-error {
    border: 1px solid rgb(255, 0, 0);
  }
  .box-image {
    position: relative;
    border: 1px solid #dddd;
  }
  .box-mdi-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
  }
  .history{
    border-radius: 4px;
    border: solid 0.8px rgba(144, 144, 144, 0.35);
    background-color: #efefef;
    padding: 5px;
  }
  .wrapper-trash {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
  }
</style>
