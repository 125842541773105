<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="600"
      scrollable
    >
      <v-card :loading="isLoading">
        <v-card-title>
          <span class="headtitle-email" align="center">{{isEdit ? $_strings.dokumenPod.dialogEdit : $_strings.dokumenPod.dialogView}}</span>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="(item, index) in documentListItems"
              :key="index"
            >
              <v-expansion-panel-header class="grey lighten-4">
                {{formatTypeDokumenPOD(item.documentType)}} {{index + 1}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <span>{{$_strings.dokumenPod.headerRiwayatCatatan}}</span>
                    <p v-if="!item.notes">-</p>
                    <v-textarea
                      v-else
                      outlined
                      dense
                      disabled
                      class="grey--text"
                      v-model="item.notes"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-if="isEdit">
                  <v-col cols="12" class="d-flex pb-0 justify-end align-center">
                    <span>{{$_strings.dokumenPod.headerRevise}}</span>
                    <v-checkbox
                      class="d-inline"
                      :disabled="!isEdit"
                      v-model="formRevise[index].isRevise"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="formRevise[index].isRevise && isEdit">
                  <v-col cols="12">
                    <span>{{$_strings.dokumenPod.headerCatatan}}</span>
                    <v-text-field
                      outlined
                      dense
                      placeholder="Catatan revisi"
                      v-model="formRevise[index].note"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>{{$_strings.dokumenPod.headerNamaDokumen}}</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="item.name"
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>{{$_strings.dokumenPod.headerNoPesanan}}</span>
                    <v-text-field
                      outlined
                      dense
                      :value="itemsShipment.find((shipment) => shipment.shipmentId === item.shipmentId).shipmentName"
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>{{$_strings.dokumenPod.headerTipeDokumen}}</span>
                    <v-text-field
                      outlined
                      dense
                      disabled
                      :value="formatTypeDokumenPOD(item.documentType)"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>{{$_strings.dokumenPod.headerLokasi}}</span>
                    <v-text-field
                      outlined
                      dense
                      :value="
                        itemsLocation.find((loc) => loc.locationId === item.locationId) ?
                          itemsLocation.find((loc) => loc.locationId === item.locationId).locationName
                          : '-'
                      "
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span>{{$_strings.dokumenPod.fotoDokumen}}</span>
                    <a @click="openImage(item, index)">
                      <viewer-image
                        :ref="'viewer_image'+index"
                        :key="'viewer_image'+index"
                        :src="item.imageUrl"
                        class="d-none"
                      />
                      <v-img
                        v-if="item.fileType === 'PDF'"
                        src="@/assets/images/pdf-file.png"
                        contain
                      >
                      </v-img>
                      <v-img
                        contain
                        v-else
                        :src="item.imageUrl"
                      >
                      </v-img>
                    </a>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <v-row v-if="isEdit" justify="end">
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      small
                      block
                      class="white--text"
                      @click="submitRevise(item, formRevise[index])"
                      :disabled="isLoading || item.isRevise === formRevise[index].isRevise"
                    >
                      {{$_strings.dokumenPod.update}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            class="mr-2 ml-auto white--text"
            @click="dialog = false"
          >
            {{$_strings.dokumenPod.cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      <dialog-view-pdf
        :visible="dialogViewPdf"
        @setDialog="setDialogByDefault"
        :urlPdf="urlPdfSelected"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import { typeDokumenPOD } from '../../../helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.formRevise = [];
        this.documentListItems.forEach((doc) => {
          this.formRevise.push({
            name: doc.name,
            note: '',
            isRevise: doc.isRevise,
          });
        });
      }
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      valid: false,
      isEdit: false,
      getDocument: [],
      documentId: 0,
      items: [],
      itemsShipment: [],
      itemsLocation: [],
      formRevise: [],
      cargoId: '',
      dialogViewPdf: false,
      urlPdfSelected: '',
      documentListItems: [],
    };
  },
  methods: {
    formatTypeDokumenPOD(documentType) {
      return typeDokumenPOD(documentType);
    },
    submitRevise(item, form) {
      const { cargoId } = this.$route.params;
      this.isLoading = true;
      this.$_services.dokumenPod.editTypeDokumenPod(cargoId, item.documentId, form)
        .then((res) => {
          this.$dialog.notify.success(this.$_strings.dokumenPod.successEditDokumenType);
          this.$emit('fetchDokumenPod');
          this.dialog = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openImage(item, index) {
      const fileType = item.fileType || '';
      const ext = (item.name && item.name.split('.').pop()) || '';
      const imageExtension = ['jpg', 'png', 'jpeg', 'image'];
      if (imageExtension.includes(ext.toLowerCase()) || imageExtension.includes(fileType.toLowerCase())) {
        this.$refs[`viewer_image${index}`][0].$el.children[0].click();
        return;
      }
      if (fileType.toLowerCase() === 'pdf' || ext.toLowerCase() === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = item.imageUrl;
        return;
      }
      window.open(item.imageUrl);
    },
    setDialogByDefault(dialog) {
      this[dialog] = !this[dialog];
    },
  },
};
</script>

<style lang="scss" scoped>
.headtitle-email{
  font-size: 16px;
  font-weight: bold;
}
.history{
  border-radius: 4px;
  border: solid 0.8px rgba(144, 144, 144, 0.35);
  background-color: #efefef;
  padding: 5px;
}
</style>
