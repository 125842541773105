<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="400"
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-html="title" class="mt-4 d-flex justify-center"></v-card-text>
      <v-card-actions>
        <v-row
          justify="center"
          class="ma-0"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              @click="submit"
            >
              {{$_strings.common.YES}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      title: {},
      action: null,
    };
  },
  methods: {
    submit() {
      this.dialog = false;
      if (this.action === 'completeDocument') {
        this.$emit('completeDocument');
        return;
      }
      this.$emit('inCompleteDocument');
    },
  },
};
</script>
