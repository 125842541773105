var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form_notes",attrs:{"lazy-validation":""}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('span',[_c('b',[_vm._v("No Kargo: "+_vm._s(_vm.$route.query.title))])])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(_vm.isLoadingLatestStatus)?_c('v-skeleton-loader',{attrs:{"type":"heading"}}):_c('span',[_c('b',[_vm._v("Status: "+_vm._s(_vm.latestStatus)+" ")])])],1),(_vm.userAccess.canCreate)?_c('v-col',[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","outlined":""},on:{"click":_vm.showDialogUploadDocument}},[_vm._v(" "+_vm._s(_vm.$_strings.dokumenPod.UPLOAD_DOCUMENT)+" ")])],1):_vm._e()],1),_c('br'),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totalItems,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.documentType",fn:function(items){return [_c('span',[_vm._v(_vm._s(_vm.formatTypeDokumenPOD(items.item.documentType)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.edit(false, item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-find")])],1)]}}],null,true)},[_c('span',[_vm._v("Lihat Detail")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary","disabled":_vm.latestStatus === 'Dokumen Dikirim Ke Shipper'
                  || _vm.latestStatus === 'Belum Kirim Dokumen'},on:{"click":function($event){return _vm.edit(true, item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}},{key:"item.isRevise",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isRevise ? 'Ya' : 'Tidak'))])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.dokumenPod.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"auto","sm":"2"}},[_c('v-btn',{staticClass:"float-left",attrs:{"elevation":"2"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b',[_vm._v(_vm._s(_vm.$_strings.dokumenPod.buttonKembali))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',{staticClass:"flex justify-end"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"float-right",attrs:{"elevation":"2","color":"primary","disabled":!!_vm.hasRevision
                  || !_vm.latestStatus
                  || _vm.latestStatus === 'Dokumen Terkirim'
                  || _vm.latestStatus === 'Dokumen Dikirim Ke Shipper'
                  || _vm.latestStatus === 'Belum Kirim Dokumen'},on:{"click":_vm.sendDocumentToShipper}},[_c('b',[_vm._v(_vm._s(_vm.$_strings.dokumenPod.buttonSendDokumen))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"float-right mr-2",attrs:{"elevation":"2","color":"primary","disabled":!!_vm.hasRevision
                || !_vm.latestStatus
                || _vm.latestStatus === 'Dokumen Tidak Lengkap'
                || _vm.latestStatus === 'Dokumen Dikirim Ke Shipper'
                || _vm.latestStatus === 'Belum Kirim Dokumen'},on:{"click":function($event){return _vm.showDialogConfirmation('inCompleteDocument')}}},[_c('b',[_vm._v(_vm._s(_vm.$_strings.dokumenPod.buttonDokumenTidakLengkap))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-2 float-right",attrs:{"elevation":"2","color":"primary","disabled":!!_vm.hasRevision
                  || !_vm.latestStatus
                  || _vm.items.length === 0
                  || _vm.latestStatus === 'Dokumen Dikirim Ke Shipper'
                  || _vm.latestStatus === 'Dokumen Lengkap'
                  || _vm.latestStatus === 'Belum Kirim Dokumen'},on:{"click":function($event){return _vm.showDialogConfirmation('completeDocument')}}},[_c('b',[_vm._v(_vm._s(_vm.$_strings.dokumenPod.buttonDokumen))])])],1)],1)],1)],1)],1),_c('DialogUploadDocument',{ref:"dialogUploadDocument",attrs:{"itemsLocation":_vm.itemsLocation,"itemsShipment":_vm.itemsShipment},on:{"fetchList":_vm.fetchDokumenPod}}),_c('Edit',{ref:"dialogEditDocument",attrs:{"setDialogEdit":_vm.setDialog,"dialogEdit":_vm.dialogEdit,"setEditDokumen":_vm.temporaryEdit,"setShipment":_vm.temporaryShipment,"setLocation":_vm.temporaryLocation,"shipmentDocumentPOD":_vm.shipmentDocumentPOD},on:{"fetchDokumenPod":_vm.fetchDokumenPod}}),_c('DialogConfirmation',{ref:"dialogConfirmation",on:{"completeDocument":_vm.completeDocument,"inCompleteDocument":_vm.inCompleteDocument}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }